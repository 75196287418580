import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    cart: {
      items: [],
    },
    user: {},
    isAuthenticated: false,
    isAdmin: false,
    token: '',
    isLoading: false,
    current_tab: 'Home'
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    async login(state, user) {
      await axios.get("/api/v1/me/")
          .then(response => {
            console.log(response.data)
            state.user = response.data
            state.isAuthenticated = true
            state.isAdmin = response.data.is_staff
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else {
              this.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    },
    logout(state) {
      state.user = {}
      state.isAuthenticated = false
      state.isAdmin = false
    },
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)

      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity + parseInt(item.quantity))
      } else {
        state.cart.items.push(item)
      }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    applyDiscountToCart(state, discount) {
      state.cart.items.forEach(item => {
        item.product.discountPrice = item.product.price - item.product.price * (discount.discount/100)
      })
      state.cart.discountCode = discount
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state, token) {
      state.token = ''
      state.isAuthenticated = false
    },
    clearCart(state) {
      state.cart = { items: [] }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    }
  },
  actions: {
  },
  modules: {
  }
})
