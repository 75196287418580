import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home'),
  },
  {
    path: '/Account',
    name: 'account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Account'),
  },
  {
    path: '/About',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
  },
  {
    path:'/:category_slug/:product_slug',
    name:'Product',
    component: () => import('../views/Product')
  },
  {
    path:'/:category_slug',
    name:'Category',
    component: () => import('../views/Category')
  },
  {
    path:'/search',
    name:'Search',
    component: () => import('../views/Search')
  },
  {
    path:'/signup',
    name:'Signup',
    component: () => import('../views/Signup')
  },
  {
    path:'/login',
    name:'login',
    component: () => import('../views/login')
  },
  {
    path:'/myorders',
    name:'MyOrders',
    component: () => import('../views/MyAccount'),
    meta: {
      requireLogin: true
    }
  },
  {
    path:'/myprofile',
    name:'MyProfile',
    component: () => import('../views/Profile'),
    meta: {
      requireLogin: true
    }
  },
  {
    path:'/admin',
    name:'Admin',
    component: () => import('../views/Admin'),
    meta: {
      requireLogin: true
    }
  },
  {
    path:'/cart/checkout',
    name:'Checkout',
    component: () => import('../views/Checkout'),
    meta: {
      requireLogin: true
    }
  },
  {
    path:'/cart/success',
    name:'Success',
    component: () => import('../views/Success'),
    meta: {
      requireLogin: true
    }
  },
  {
    path:'/cart',
    name:'Cart',
    component: () => import('../views/Cart')
  }

];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// GOOD

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'login', query: { to: to.path } });
  } else {
    next()
  }
})

export default router
