<template>
    <tr>
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"><router-link :to="item.product.get_absolute_url">{{ item.product.name }}</router-link></td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ item.specs.name }}</td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ item.size.size }}</td>
        <td v-if="item.product.discountPrice" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span class="line-through">{{ item.product.price }}</span> {{ item.product.discountPrice}} €
        </td>
        <td v-else class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {{ item.product.price }} €
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ item.quantity }}
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ getItemsTotal(item).toFixed(2) }}€</td>
        <td>
          <a @click="decrementQuantity(item)">-</a>
          <a @click="incrementQuantity(item)">+</a>
        </td>
        <td>
          <button class="delete" @click="removeFromCart(item)">Delete</button>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "CartItem",
        props: {
            initialItem: Object,
            cart: Object
        },
        emits: ['removeFromCart'],
        data() {
            return {
                item: this.initialItem,
              cart: this.cart
            }
        },
        methods: {
            getItemsTotal(item) {
              if (item.product.discountPrice) {
                return item.quantity * item.product.discountPrice
              } else {
                return item.quantity * item.product.price
              }
            },
            decrementQuantity(item) {
                item.quantity -= 1;
                if (item.quantity <= 0) {
                  this.removeFromCart(item)
                }
                this.updateCart()
            },
            incrementQuantity(item) {
                item.quantity += 1;
                this.updateCart()
            },
            updateCart() {
                localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))
            },
            removeFromCart(item) {
              this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)
              this.updateCart()
            },
        }
    }
</script>

<style scoped>

</style>
