<template>
    <div class="select-none mb-6 w-full">
        <div class="relative  pb-64">
            <router-link :to="product.get_absolute_url" class="cursor-pointer">
                <img class="absolute w-full h-full rounded-lg object-cover border-b shadow-md cursor-pointer" :src="product.get_thumbnail" :alt="product.name" />
            </router-link>
        </div>

        <div class="px-4 -mt-16 relative font-poppins">
            <div class="bg-white rounded-lg shadow-lg border">
                <div class="p-5">
                    <div class="flex justify-between items-center">
                        <router-link class="text-gray-800 block mt-2 truncate hover:underline font-medium text-lg" :to="product.get_absolute_url">
                          {{ product.name }}
                        </router-link>

                        <span v-if="!product.is_in_stock" class="text-red-500 rounded-full bg-red-200 px-3 py-1 text-xs select-none">
                          Out Of Stock
                        </span>

                    </div>
                </div>

                <div class="flex justify-between items-center pb-3 px-4">
                    <div>
                        <div class="text-gray-800 text-lg">
                            <span class="font-medium">{{ product.price }} €</span>
                        </div>
                    </div>

                    <div>
                        <router-link class="btn-link flex items-center text-xs text-indigo-600 hover:underline" :to="product.get_absolute_url">
                            View Product &rarr;
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "Smallcard",
  props: [
    'product'
  ],
  mounted() {
    console.log(this.product)
  }
}
</script>

<style scoped>

</style>
