<template>
  <div>
    <div class="bg-gray-800 pb-32 font-poppins">
      <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="border-b border-gray-700">
            <div class="flex items-center justify-between h-16 px-4 sm:px-0">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <img class="h-8 w-8" src="./assets/img/durban.jpg" alt="Workflow" />
                </div>
                <div class="hidden md:block">
                  <div class="ml-10 flex items-baseline space-x-4">
                    <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</router-link>
                  </div>
                </div>
              </div>

              <div class="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                <div class="max-w-lg w-full lg:max-w-xs">
                  <label for="search" class="sr-only">Search</label>
                  <form class="relative text-gray-400 focus-within:text-gray-600" method="get" action="/search">
                    <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                      <SearchIcon class="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input id="search" class="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white focus:border-white sm:text-sm" placeholder="Search" type="search" name="search" />
                  </form>
                </div>
              </div>

              <div class="flex lg:hidden">
                <!-- Mobile menu button -->
                <DisclosureButton class="bg-grey-600 p-2 rounded-md inline-flex items-center justify-center text-grey-200 hover:text-white hover:bg-grey-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-grey-600 focus:ring-white">
                  <span class="sr-only">Open main menu</span>
                  <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                  <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                </DisclosureButton>
              </div>
              <div class="hidden lg:block lg:ml-4">
                <div class="flex items-center">

                  <!-- Profile dropdown -->
                  <Menu as="div" class="ml-3 relative flex-shrink-0">
                    <div>
                      <MenuButton class="bg-grey-600 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-grey-600 focus:ring-white">
                        <span class="sr-only">Open user menu</span>
                        <img class="rounded-full h-8 w-8" src="./assets/img/profil.png" alt="" />
                      </MenuButton>
                    </div>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <MenuItem v-slot="{ active }" v-if="$store.state.isAdmin">
                          <router-link to="/admin" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                            Admin
                          </router-link>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                          <router-link to="/myorders" :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                            My orders
                          </router-link>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                          <router-link to="/myprofile" :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                            My profile
                          </router-link>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                          <router-link to="/cart" :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                            <p v-if="cartTotalLength">Cart ({{ cartTotalLength }})</p>
                            <p v-else>Cart</p>
                          </router-link>
                        </MenuItem>
                        <MenuItem v-if="!this.$store.state.isAuthenticated" v-slot="{ active }">
                          <router-link to="/login" :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                            Login
                          </router-link>
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel class="border-b border-gray-700 md:hidden">
          <div class="px-2 py-3 space-y-1 sm:px-3">
            <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base']"
                :aria-current="item.current ? 'page' : undefined"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              {{ item.name }}
            </router-link>
          </div>
          <div class="pt-4 pb-3 border-t border-gray-700">
            <div class=" px-2 space-y-1">
              <router-link v-if="this.$store.state.isAdmin" to="/admin" class="block px-3 py-2 rounded-md text-base text-gray-400 hover:text-white hover:bg-gray-700">
                Admin
              </router-link>
              <router-link to="/myorders" class="block px-3 py-2 rounded-md text-base text-gray-400 hover:text-white hover:bg-gray-700">
                My orders
              </router-link>
              <router-link to="/myprofile" class="block px-3 py-2 rounded-md text-base text-gray-400 hover:text-white hover:bg-gray-700">
                My profile
              </router-link>
              <router-link to="/cart" class="block px-3 py-2 rounded-md text-base text-gray-400 hover:text-white hover:bg-gray-700">
                <p v-if="cartTotalLength">Cart ({{ cartTotalLength }})</p>
                <p v-else>Cart</p>
              </router-link>
              <router-link v-if="!this.$store.state.isAuthenticated" to="/login" class="block px-3 py-2 rounded-md text-base text-gray-400 hover:text-white hover:bg-gray-700">
                Login
              </router-link>
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>

      <div class="is-loading-bar text-center" :class="{'is-loading': this.$store.state.isLoading}">
        <div class="lds-dual-ring"></div>
      </div>

      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-light text-white font-poppins">
            Durban
          </h1>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <router-view class="mb-auto"/>
        </div>
        <!-- /End replace -->
      </div>


    </main>
  </div>
  <Footer class="fixed inset-x-0 bottom-0 bg-white" />
</template>

<script>
  import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { SearchIcon } from '@heroicons/vue/solid'
  import { BellIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'
  import Footer from "./components/Footer";
  import Navbar from "./components/Navbar";
  import axios from 'axios'

  const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
  const navigation = [
    { name: 'Home', href: '/', current: true },
    { name: 'About', href: '/about', current: false },
    { name: 'Clothes', href: '/clothes', current: false },
    { name: 'Sticks', href: '/sticks', current: false },
    { name: 'Accessories', href: '/accessories', current: false },
  ];
  const userNavigation = [
    { name: 'My account', href: '/myaccount' },
    { name: 'Sign out', href: '/' },
  ]

  export default {
    components: {
      Navbar,
      Footer,
      Disclosure,
      DisclosureButton,
      DisclosurePanel,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      BellIcon,
      MenuIcon,
      SearchIcon,
      XIcon,
    },
    data () {
      return {
        cart: {
          items: []
        },
        user,
        navigation,
        userNavigation,
        query: ""
      }
    },
    async beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
        await this.$store.commit('login')
        console.log(this.$store.state.user)
        console.log(this.$store.state.isAuthenticated)
        console.log(this.$store.state.isAdmin)
      } else {
        axios.defaults.headers.common['Authorization'] = ''
      }
    },
    mounted() {
      this.cart = this.$store.state.cart;
    },
    methods: {
      logout() {
        axios.defaults.headers.common['Authorization'] = ''

        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem('userid')
        this.$store.commit('removeToken')

        this.$router.push('/')
      },
    },
    computed: {
      cartTotalLength() {
        let total = 0;
        for (let i = 0; i < this.cart.items.length; i++) {
          total += this.cart.items[i].quantity
        }
        return total
      }
    },
  }
</script>

<style lang="scss">
  .wrapper {
    width: 0.75%;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #ccc;
    border-color: #ccc transparent #ccc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .is-loading-bar {
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &.is-loading {
      height: 80px;
    }
  }

</style>
