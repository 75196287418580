<template>
  <div class="md:m-4">
    <div class="hidden md:block">
      <vueper-slides autoplay fade>
        <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.img" />
      </vueper-slides>
    </div>

    <h1 class="font-poppins text-lg md:text-2xl mt-6 mb-2">Check our latest products:</h1>

    <div class="w-full h-full md:h-auto grid grid-cols-1 md:grid-cols-3 gap-4 px-2 py-4">
      <!-- Desktop -->
      <smallcard class="hidden md:block" v-for="product in latest" :key="product.id" :product="product"/>
      <!-- Mobile -->
      <router-link v-for="product in latest" :key="product.id" :to="product.get_absolute_url" class="group block md:hidden">
        <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
          <img :src="product.get_thumbnail" :alt="product.name" class="w-full h-full object-center object-cover group-hover:opacity-75" />
        </div>
        <div class="grid grid-cols-2 content-center">
          <div class="text-sm text-gray-700 items-baseline p-1">
            {{ product.name }}
          </div>
          <p class="text-lg font-medium text-gray-900 text-right">
            {{ product.price }} €
          </p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Smallcard from "../components/Smallcard";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {createToast} from "mosha-vue-toastify";
import CartItem from "@/components/CartItem";

export default {
  name: 'Home',
  data () {
    return {
      latest: [],
      slides: [
        {
          id: 1,
          title: 'Image 1',
          content: 'Test Content',
          img: require('../assets/img/stick_coral_2.jpg')
        },
        {
          id: 2,
          title: 'Image 2',
          content: 'Test Content',
          img: require('../assets/img/stick_blue.jpg')
        },
        {
          id: 3,
          title: 'Image 3',
          content: 'Test Content',
          img: require('../assets/img/signin.jpg')
        }
      ],
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        wrapAround: true,
        autoplay: 4000,
        transition: 1300
      },
    }
  },
  components: {
    CartItem,
    Smallcard,
    VueperSlides,
    VueperSlide
  },
  mounted() {
    this.getLatestProduct();

    document.title = 'Home | Durban'
  },
  methods: {
    async getLatestProduct() {
      this.$store.commit('setIsLoading', true);

      await axios
      .get('/api/v1/latest-products/')
      .then(response => {
        this.latest = response.data

      })
      .catch(error => {
        console.log(error);

        createToast('Something went wrong. Please Try again.',
                {
                  position: 'top-right',
                  type: 'danger',
                  transition: 'bounce',
                })
      });

      this.$store.commit('setIsLoading', false)
    }
  }
}
</script>

<style scoped>
  .carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: #642afb;
    color: white;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel__slide {
    padding: 10px;
  }

  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
  }
</style>
